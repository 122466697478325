<template>
<div class="app flex-row align-items-center container-wrapper" :style="container_background_image">
    <div class="container col-md-4 pt-3 pb-3 pt-md-4 pb-md-4">
        <b-row class="m-0 justify-content-center shadow-2">
            <b-col class="p-0 bg-white">
                <q-card no-body class="p-4 full-height register-form" light>
                    <AppHeader class="header-lang no-border-bottom pl-3 pr-1 mb-0" :style="{position: 'relative', marginTop: '-1rem'}">
                        <switch-lang></switch-lang>
                    </AppHeader>
                    <div class="full-width navbar-brand text-center pt-0">
                        <img class="navbar-brand-full" :src="appLogo()" height="70" alt="Dynamic Studio Logo">
                    </div>
                    <b-form @submit.prevent="loginUser">
                        <p class="text-center font-weight-bold mb-4 pb-4">{{ translate('login_account') }}</p>
                        
                        <b-input-group class="mb-1">
                            <!-- <label class="m-0">{{ translate('email') }}</label> -->
                            <q-input light square dense :error-message="''" no-error-icon :error="submitted && $v.user.email.$error" color="primary" type="text" v-model="user.email" :placeholder="translate('email')">
                                <template v-slot:append>
                                    <q-icon name="icon-envelope" />
                                </template>
                            </q-input>
                            <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                                <span v-if="!$v.user.email.email">Email is invalid</span>
                            </div>
                        </b-input-group>

                        <b-input-group class="mb-1">
                            <!-- <label class="m-0">{{ translate('password') }}</label> -->
                            <q-input light square dense :error-message="''" no-error-icon :error="submitted && $v.user.password.$error" color="primary" type="password" v-model="user.password" :placeholder="translate('password')">
                                <template v-slot:append>
                                    <q-icon name="icon-lock" />
                                </template>
                            </q-input>
                        </b-input-group>

                        <b-input-group v-if="invalid_msg" class="mb-3">
                            <q-item class="bg-red-2 border-top-red">
                                <q-item-section class="is-invalid text-negative">{{ invalid_msg }}</q-item-section>
                            </q-item>
                        </b-input-group>

                        <b-input-group class="m-0 mb-1 row">
                            <router-link class="col-md p-0 text-dark small" :to="{name:'ForgotPassword'}">{{ translate('forgot_password') }}</router-link>
                            <router-link class="col-md p-0 text-dark small text-md-right" :to="{name:'ResendActivationEmail'}">{{ translate('resend_activation_email') }}</router-link>
                        </b-input-group>
                        
                        <div class="text-center">
                            <q-btn class="full-width mt-2 mb-2 icon-right-leftspace" icon-right="login" color="info" square no-caps type="submit">{{ translate('login') }}</q-btn>
                        </div>
                    </b-form>

                    <div class="full-width text-center">
                        <p class="mb-3 mt-2">
                            {{ translate('register_here') }}
                        </p>
                        <q-btn icon-right="send" color="dark" square no-caps :to="{name: 'Register'}" type="button" class="full-width no-text-decoration icon-right-leftspace">{{ translate('register') }}</q-btn>
                    </div>
                </q-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import {
    Header as AppHeader
} from '@coreui/vue'
import {
    required,
    email,
    minLength,
    sameAs
} from "vuelidate/lib/validators"
import LangNavbarAuth from './LangNavbarAuth'

export default {
    name: 'Login',
    components: {
        AppHeader,
        'switch-lang': LangNavbarAuth
    },
    computed: {
        random_background: function () {
            var array = ['https://img5.goodfon.com/original/1920x1080/8/fd/sportivnaia-para-zal-trenazhernyi-zal-sport-tiaga-ganteli-pl.jpg', '/img/backgrounds/background_login.jpg']
            var random = this.$shuffle(array)

            return random[0]
        },
        container_background_image: function () {
            return false
            return 'background-image: url(' + this.random_background + ');'
        },
    },
    data: function () {
        return {
            invalid_msg: null,
            user: {
                email: null,
                password: null
            },
            msg: null,
            submitted: false,
            selected_language: 'ro',
        }
    },
    validations: {
        user: {
            email: {
                required,
                email
            },
            password: {
                required
            },
        }
    },
    methods: {
        loginUser() {
            this.submitted = true
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            var data = this.user
            data['lang'] = this.$appSettings.lang ? this.$appSettings.lang : this.selected_language
            
            axios.post(baseUrl + 'auth/login', data)
                .then(response => {
                    var resp = response.data
                    if (resp.access_token != undefined && resp.refresh_token != undefined) {
                        localStorage.setItem('access_token', resp.access_token)
                        localStorage.setItem('refresh_token', resp.refresh_token)
                        this.msg = 'login ok'
                        this.$appSettings.userNav = this.$appSettings.currentUserMenu()

                        // Redirect to 'Home' after login.
                        // Use undefined for the second argument to fix reduntant navigation to current location error.
                        this.$router.push({
                            name: 'Home'
                        }, undefined, () => { 
                            location.href = this.$route.hash 
                        })
                    } else {
                        this.msg = response.data.msg
                        var message = 'login_' + this.msg
                        this.invalid_msg = this.translate(message)
                    }
                })
                .catch(error => {
                    // console.log(error)
                })
        },

    }
}
</script>
